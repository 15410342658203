import TollRoadService from "@/services/TollRoadService";
import ReportStatusesService from "@/services/ReportStatusesService";
import TollRoadStatusesService from "@/services/TollRoadStatusesService";
import ReportCausesService from "@/services/ReportCausesService";
import AlertsService from "@/services/AlertsService";
import LeasingCompaniesService from "@/services/LeasingCompaniesService";
import DriverService from "@/services/DriverService";
import VehicleService from "@/services/VehicleService";
import TransferStatusesService from "@/services/TransferStatusesService";
import MunicipalitiesService from "@/services/MunicipalitiesService";
import ProfileService from "@/services/ProfileService";

const DateSearchTypes = {
  DateTaken: {
    Value: 1,
    Text: 'תאריך קבלת הדו"ח',
    fromField: "FromDateTaken",
    toField: "ToDateTaken",
  },

  LastPayDate: {
    Value: 2,
    Text: "תאריך תשלום אחרון",
    fromField: "FromLastPayDate",
    toField: "ToLastPayDate"
  },

  PaymentDate: {
    Value: 3,
    Text: "תאריך תשלום דוח",
    fromField: "FromPaymentDate",
    toField: "ToPaymentDate"
  },

  ApprovalDate: {
    Value: 4,
    Text: "תאריך אישור תשלום",
    fromField: "FromApprovalDate",
    toField: "ToApprovalDate"
  },

  VerificationDate: {
    Value: 5,
    Text: "תאריך אימות",
    fromField: "FromVerificationDate",
    toField: "ToVerificationDate"
  },

  SignedDate: {
    Value: 6,
    Text: 'תאריך חתימת עו"ד על הסבה חברה',
    fromField: "FromSignedDate",
    toField: "ToSignedDate"
  },

  DriverSignedDate: {
    Value: 7,
    Text: 'תאריך חתימת עו"ד על הסבת נהג',
    fromField: "FromDriverSignedDate",
    toField: "ToDriverSignedDate"
  },

  CreateDate: {
    Value: 8,
    Text: "תאריך יצירה במערכת",
    fromField: "FromCreateDate",
    toField: "ToCreateDate",
    role: ["admin"]
  },
  TransferredDate: {
    Value: 9,
    Text: "תאריך הפקת מסמכי הסבת חברה",
    fromField: "FromTransferredDate",
    toField: "ToTransferredDate"
  },

  TransferCompleteDate: {
    Value: 10,
    Text: "תאריך השלמת הסבת חברה",
    fromField: "FromTransferCompleteDate",
    toField: "ToTransferCompleteDate"
  },

  DriverTransferCompleteDate: {
    Value: 11,
    Text: "תאריך השלמת הסבת נהג",
    fromField: "FromDriverTransferCompleteDate",
    toField: "ToDriverTransferCompleteDate"
  },
  VehicleCustomerContract: {
    Value: 12,
    Text: "תאריך חוזה פעיל",
    fromField: "FromDate",
    toField: "ToDate"
  },
  TransferredDriverDate: {
    Value: 13,
    Text: "תאריך הפקת מסמכי הסבת נהג",
    fromField: "FromDriverTransferredDate",
    toField: "ToDriverTransferredDate"
  },
  FirstDocumentTransferCompleteDate: {
    Value: 14,
    Text: "תאריך הפקה ראשונה של מסמכי הסבת חברה",
    fromField: "FromFirstTransferredDate",
    toField: "ToFirstTransferredDate"
  },
  FirstDocumentTransferDriverCompleteDate: {
    Value: 15,
    Text: "תאריך הפקה ראשונה של מסמכי הסבת נהג",
    fromField: "FromDriverFirstTransferredDate",
    toField: "ToDriverFirstTransferredDate"
  }
};

const TasksDateSearchTypes = {
  DueDate: {
    Value: 1,
    Text: "תאריך יעד",
    fromField: "FromDueDate",
    toField: "ToDueDate"
  },
  CreateDate: {
    Value: 2,
    Text: "תאריך יצירה",
    fromField: "FromCreateDate",
    toField: "ToCreateDate"
  }
};

const fields = {
  IsPaid: {
    Display: "שולם",
    Query: "IsPaid",
    Type: "FilterSelect",
    AllowEmpty: false,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: true,
        Text: "שולם"
      },
      {
        Value: false,
        Text: "לא שולם"
      }
    ],
    Tooltip: 'סינון ע"פ דוח סטטוס תשלום'
  },
  ReportStatus: {
    Display: "סטטוס דוח",
    Query: "Status",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: ReportStatusesService.get,
    Tooltip: 'סינון ע"פ דוח סטטוס דוח: כפל, גביה, ערעור וכו...'
  },
  // NotReportStatus: {
  //     IsNot: true,
  //     Display: 'ללא סטטוס דוח',
  //     Query: 'NotStatus',
  //     Type: 'FilterMultiDropdown',
  //     AllowEmpty: true,
  //     OptionsAsync: ReportStatusesService.get,
  //     // OptionsAsync: (arr) => ReportStatusesService.get()
  //     //     .then((data) => {
  //     //         const i = [];
  //     //         data.forEach((cell) => ((!i.includes(cell.Value)) ? i.push(arr.find((dell) => cell.Value === dell)) : null));
  //     //         return i.filter((res) => res);
  //     //     }),
  //     Tooltip: 'סינון ע"פ דוח סטטוס דוח: כפל, גביה, ערעור וכו...',
  // },
  ReportStatusApproved: {
    Display: "סינון לפי אישור",
    Query: "StatusApproved",
    Type: "FilterSelect",
    AllowEmpty: true,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: true,
        Text: "דוחות שאושרו לתשלום"
      },
      {
        Value: false,
        Text: "דוחות שלא אושרו לתשלום"
      }
    ],
    Tooltip: "סינון לפי אישור דוח"
  },
  CauseIds: {
    Display: "סיבת הדוח",
    Query: "Reason",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: ReportCausesService.get,
    Tooltip: 'סיבת העבירה כפי שמופיעה בדו"ח'
  },
  Alerts: {
    Display: "התראות",
    Query: "AlertType",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: AlertsService.get,
    Tooltip: "התראות על דוח מציינות שלדוח זה קיימים פרמטרים העשויים להשפיע על תהליך הטיפול של החברה"
  },
  // TransferStatus: {
  //   Display: "סטטוס הסבת חברה",
  //   Query: "TransferStatus",
  //   Type: "FilterMultiDropdown",
  //   AllowEmpty: true,
  //   OptionsAsync: () =>
  //     TransferStatusesService.get().then((data) => data.filter((i, idx) => idx !== 0)),
  //   Tooltip: 'סטטוס ההסבה של חברה ע"פ המערכת'
  // },
  Profile: {
    Display: "לקוח",
    Query: "ProfileIds",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    isManagedOnly: false,
    isTransferOnly: true,
    OptionsAsync: () =>
      LeasingCompaniesService.get().then((data) =>
        ProfileService.get().then((r) => {
          let user;
          const idx = data.findIndex((i) => i.IdNumber === r.IdNumber);
          if (idx >= 0) {
            user = data.splice(idx, 1);
          } else {
            user = [
              {
                IdNumber: r.IdNumber,
                Text: r.Name,
                Value: r.Id,
                ForbidTransfer: r.ForbidTransfer
              }
            ];
          }
          data.unshift(...user);
          return data;
        })
      ),
    Tooltip: "החברה אליה הרכב משוייך בזמן קבלת הדוח"
  },
  Driver: {
    Display: "נהג",
    Query: "DriverId",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: DriverService.get,
    Tooltip: "הנהג ברכב בזמן ביצוע העבירה"
  },
  VehicleOwner: {
    Display: "בעל רכב",
    Query: "OwnerProfileId",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: LeasingCompaniesService.get,
    Tooltip: "הבעלים העכשווי של הרכב"
  },
  TransferStatus: {
    Display: "סטטוס הסבת חברה",
    Query: "TransferStatus",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: () =>
      TransferStatusesService.get().then((data) => data.filter((i, idx) => idx !== 0)),
    Tooltip: 'סטטוס ההסבה של חברה ע"פ המערכת'
  },
  TransferStatusOnlyNotTransferredAndRejected: {
    Display: "סטטוס הסבת חברה",
    Query: "TransferStatus",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: () =>
      TransferStatusesService.get().then((data) => data.filter((i, idx) => idx === 0 || idx === 14)),
    Tooltip: 'סטטוס ההסבה של חברה ע"פ המערכת'
  },
  TransferStatusWNotTransferred: {
    Display: "סטטוס הסבת חברה",
    Query: "TransferStatus",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: TransferStatusesService.get,
    Tooltip: 'סטטוס ההסבה של חברה ע"פ המערכת'
  },
  DriverTransferStatus: {
    Display: "סטטוס הסבת נהג",
    Query: "DriverTransferStatus",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: () =>
      TransferStatusesService.get().then((data) => data.filter((i, idx) => idx !== 0)),
    Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת'
  },
  DriverTransferStatusWNotTransferred: {
    Display: "סטטוס הסבת נהג",
    Query: "DriverTransferStatus",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: TransferStatusesService.get,
    Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת'
  },
  ReportNumber: {
    Display: "מספר דוח",
    Query: "ReportNumber",
    Type: "FilterString",
    AllowEmpty: true,
    Tooltip: 'מספר הדו"ח ע"פ הרשות'
  },
  MuniIdNumber: {
    Display: "מזהה עיריה",
    Query: "MuniIdNumber",
    Type: "FilterString",
    AllowEmpty: true,
    Tooltip: 'מספר מזהה עיריה'
  },
  PostOfficeReference: {
    Display: "מספר מעקב",
    Query: "PostOfficeReference",
    Type: "FilterString",
    AllowEmpty: true,
    Tooltip: 'מספר מעקב ע"פ דואר ישראל'
  },
  Other: {
    Display: "אחר",
    Query: "SearchTerm",
    Type: "FilterString",
    AllowEmpty: true,
    Tooltip: "חיפוש כללי"
  },
  VehicleOnlyOwnedByMe: {
    Display: "בבעלותי בלבד",
    Query: "OnlyOwnedByMe",
    Type: "FilterBoolean",
    AllowEmpty: true,
    Tooltip: "סינון לפי רכבים שבבעלותי בלבד"
  },
  VehicleType: {
    Display: "סוג רכב",
    Query: "VehicleType",
    Type: "FilterSelect",
    AllowEmpty: false,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: 1,
        Text: "מסחרי"
      },
      {
        Value: 2,
        Text: "פרטי"
      }
    ],
    Tooltip: 'סינון ע"פ סוג הרכב'
  },
  PlateNumber: {
    Display: "לוחית רישוי",
    Query: "plateNumber",
    Type: "FilterTypeAhead",
    AllowEmpty: true,
    OptionsAsync: (term) =>
      VehicleService.searchPlateNumber(term).then((r) =>
        r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
      ),
    Tooltip: "לוחית הרישוי של הרכב בזמן העבירה"
  },
  Municipality: {
    Display: "עיריה",
    Query: "MunicipalityId",
    Type: "FilterTypeAhead",
    AllowEmpty: true,
    OptionsAsync: MunicipalitiesService.get,
    Tooltip: "הרשות שמטעמה נתקבל הדוח"
  },
  TollRoadStatus: {
    Display: "סטטוס",
    Query: "Status",
    Type: "FilterMultiDropdown",
    Tooltip: "סטטוס הדוח",
    AllowEmpty: true,
    OptionsAsync: TollRoadStatusesService.get
  },
  TollRoadType: {
    Display: "כביש אגרה",
    Query: "TollRoad",
    Type: "FilterSelect",
    Tooltip: "סטטוס הדוח",
    AllowEmpty: true,
    OptionsAsync: TollRoadService.get
  },
  AccountNumber: {
    Display: "מספר חשבון",
    Query: "AccountNumber",
    Type: "FilterString",
    Tooltip: "מספר חשבון",
    AllowEmpty: true
  },
  InvoiceNumber: {
    Display: "מספר חשבונית",
    Query: "InvoiceNumber",
    Type: "FilterString",
    Tooltip: "מספר חשבונית",
    AllowEmpty: true
  },
  DateTypes: {
    IsComplex: true,
    Display: "סוג חיפוש",
    Id: "DateTypes",
    Type: "FilterDateTypes",
    Tooltip: "חיפוש לפי סוג תאריך",
    Options: [
      { ...DateSearchTypes.DateTaken },
      { ...DateSearchTypes.LastPayDate },
      { ...DateSearchTypes.PaymentDate },
      { ...DateSearchTypes.ApprovalDate },
      { ...DateSearchTypes.VerificationDate },
      { ...DateSearchTypes.CreateDate },
      { ...DateSearchTypes.SignedDate },
      { ...DateSearchTypes.DriverSignedDate },
      { ...DateSearchTypes.TransferredDate },
      { ...DateSearchTypes.TransferredDriverDate },
      { ...DateSearchTypes.TransferCompleteDate },
      { ...DateSearchTypes.DriverTransferCompleteDate },
      { ...DateSearchTypes.FirstDocumentTransferCompleteDate },
      { ...DateSearchTypes.FirstDocumentTransferDriverCompleteDate }
    ]
  },

  // Tasks
  AssigneeUserId: {
    Display: "משתמש משוייך",
    Query: "AssigneeUserId",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: () =>
      ProfileService.users().then((r) =>
        r.data.map((u) => ({
          Value: u.Id,
          Text: u.FullName || u.UserName
        }))
      ),
    Tooltip: "החברה אליה הרכב משוייך בזמן קבלת הדוח"
  },
  TaskType: {
    Display: "סוג המשימה",
    Query: "TaskType",
    Type: "FilterSelect",
    AllowEmpty: false,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: 1,
        Text: "שיחת טלפון"
      },
      {
        Value: 2,
        Text: "אימייל"
      },
      {
        Value: 3,
        Text: "לבצע"
      }
    ],
    Tooltip: 'סינון ע"פ סוג המשימה'
  },
  Priority: {
    Display: "דחיפות",
    Query: "Priority",
    Type: "FilterSelect",
    AllowEmpty: false,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: 1,
        Text: "גבוהה"
      },
      {
        Value: 2,
        Text: "רגילה"
      },
      {
        Value: 3,
        Text: "נמוכה"
      }
    ],
    Tooltip: 'סינון ע"פ דחיפות המשימה'
  },
  SearchFilterType: {
    IsComplex: true,
    Display: "סוג חיפוש",
    Id: "SearchFilterType",
    Type: "FilterDateTypes",
    Tooltip: "חיפוש לפי סוג תאריך",
    Options: [{ ...TasksDateSearchTypes.DueDate }, { ...TasksDateSearchTypes.CreateDate }]
  },

  // Drivers
  IdNumber: {
    Display: "תעודת זהות",
    Query: "IdNumber",
    Type: "FilterString",
    Tooltip: "תעודת זהות",
    AllowEmpty: true
  },
  FullName: {
    Display: "שם",
    Query: "FullName",
    Type: "FilterString",
    Tooltip: "שם",
    AllowEmpty: true
  },
  PhoneNumber: {
    Display: "טלפון",
    Query: "PhoneNumber",
    Type: "FilterString",
    Tooltip: "טלפון",
    AllowEmpty: true
  },
  City: {
    Display: "עיר",
    Query: "City",
    Type: "FilterString",
    Tooltip: "עיר",
    AllowEmpty: true
  },
  WorkerNumber: {
    Display: "מספר עובד",
    Query: "WorkerNumber",
    Type: "FilterString",
    Tooltip: "מספר עובד",
    AllowEmpty: true
  },
  TrackingIdNumber: {
    Display: "מספר מעקב",
    Query: "TrackingIdNumber",
    Type: "FilterString",
    Tooltip: "מספר מעקב",
    AllowEmpty: true
  },
  IsDriverLicenseExist: {
    Display: "קיים רשיון נהיגה",
    Query: "IsDriverLicenseExist",
    Type: "FilterSelect",
    AllowEmpty: false,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: true,
        Text: "כן"
      },
      {
        Value: false,
        Text: "לא"
      }
    ],
    Tooltip: "קיים רשיון נהיגה"
  },
  ManagerId: {
    Display: "מנהל",
    Query: "ManagerId",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: DriverService.get,
    Tooltip: "חיפוש לפי שם המנהל"
  },
  IsActive: {
    Display: "פעיל",
    Query: "IsActive",
    Type: "FilterSelect",
    AllowEmpty: false,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: true,
        Text: "כן"
      },
      {
        Value: false,
        Text: "לא"
      }
    ],
    Tooltip: "פעיל"
  }
};

export default {
  list: () => ({ ...fields }),
  DateSearchTypes
};
